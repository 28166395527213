import React, { Fragment } from "react"

export const deliveryChannelUpdates = [
  {
    courierOption: "MedGrocer",
    message: (
      <Fragment>We'll reach out to you if additional charges apply</Fragment>
    ),
  },
  {
    courierOption: "Globo",
    message: (
      <Fragment>We'll reach out to you if additional charges apply</Fragment>
    ),
  },
]
